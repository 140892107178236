
import { useHistory } from "react-router-dom";

function selectOption(value, returnFunction) {
    let needMoreInfo = {
        "snc": 7,
        "spa": 4,
        "srl": 4,
        "cooperativa": 5,
    }

    returnFunction({
        data: { "job": value },
        meta: { nextPage: needMoreInfo[value] || 7 }
    });
}



export const Finance_step_2 = (props) => {


    const history = useHistory();
    let buttonStyle = {
        backgroundColor: props.params.button_color,
        color: "white"
    }

    return <>
        <h1 className="has-text-centered subtitle" >Che tipo di azienda?</h1>
        {professioni.map((p, index) => {

            return <button class="button is-fullwidth mb-2" key={index} style={buttonStyle} onClick={() => { selectOption(p.value, props.returnData) }}>{p.description}</button>

        })}

        <button class="button is-ghost" onClick={history.goBack}>Indietro</button>
    </>
};


export default Finance_step_2;



const professioni = [
    { id: 8, description: 'Dipendente SNC/SAS', value: 'snc' },
    { id: 5, description: 'Dipendente SPA (indeterminato)', value: 'spa' },
    { id: 6, description: 'Dipendente SRL (indeterminato)', value: 'srl' },
    { id: 4, description: 'Dipendente cooperativa (indeterminato)', value: 'cooperativa' }
]


