import { Formik, Field, Form } from 'formik';
import { useState } from 'react';
import Autocomplete from "react-autocomplete"
import { useHistory } from "react-router-dom";
import ApiService from '../../services/api';


function validateCap(value, e) {
    let error

    if (!value) {
        error = "Seleziona una città dal menu"
    }
    return error;
}


const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const Finance_step_7 = (props) => {
    const [items, setItems] = useState([])
    const [selected, setSelected] = useState([])

    let buttonStyle = {
        backgroundColor: props.params.button_color,
        color: "white"
    }


    const history = useHistory();

    return (
        <div>
            <h1 className="has-text-centered subtitle" >Città di residenza</h1>
            <Formik
                initialValues={{
                    zipcode: '',
                    name: ""
                }}
                onSubmit={async (values) => {
                    props.returnData({
                        data: { "zip": values },
                        meta: { nextPage: 8, state: "setup" }
                    })
                }}
            >
                {({ values, errors, touched, isValidating, isSubmitting, setFieldValue, validateField }) => (
                    <Form>
                        <div className="field">
                            <div className="control has-icons-right">
                                <Field type="hidden" name="zipcode" validate={validateCap} />
                                <Autocomplete
                                    inputProps={(errors.zipcode && touched.zipcode) ? { className: "input is-danger" } : { className: "input" }}
                                    wrapperStyle={{ display: "inherit" }}
                                    menuStyle={{
                                        zIndex: 999,
                                        position: "fixed",
                                        borderRadius: '3px',
                                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                        background: 'rgba(255, 255, 255, 0.9)',
                                        padding: '2px 0',
                                        fontSize: '90%',
                                        position: 'fixed',
                                        overflow: 'auto',
                                    }}
                                    getItemValue={(item) => item.name}
                                    items={items}
                                    renderItem={(item, isHighlighted) => <div className="p-3" style={{ backgroundColor: isHighlighted ? props.params.button_color : 'white' }}>{item.zipcode} - {item.name} ({item.provincia})</div>}
                                    value={values.name}
                                    onChange={(e) => { setFieldValue('name', e.target.value); setFieldValue('zipcode', ""); getZipcode(e.target.value, setItems); validateField("zipcode") }}
                                    onSelect={(val, item) => { setFieldValue('name', item.name); setFieldValue('zipcode', item.zipcode); validateField("zipcode") }}

                                />

                                {errors.zipcode && touched.zipcode && <span className="icon is-small is-right">
                                    <i className="fas fa-exclamation-triangle"></i>
                                </span>}

                            </div>
                            {errors.zipcode && touched.zipcode && <p className="help is-danger">{errors.zipcode}</p>}
                        </div>
                        <div className="columns is-mobile">
                            <div className="column">
                                <button class="button is-ghost" onClick={history.goBack}>Indietro</button>
                            </div>
                            <div className="column has-text-right">
                                <button type="submit" disabled={isSubmitting} className="button mb-2" style={buttonStyle}>Avanti</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

const getZipcode = async (zip, setItems) => {
    let items = await ApiService.getCityByZipCode(zip);
    setItems(items)
}


export default Finance_step_7;


