import { useHistory } from "react-router-dom";

function selectOption(value,returnFunction) {
   
    returnFunction({
        data: { "layoff": value}, 
        meta: {nextPage: 7}
    });
}
    


export const Finance_step_12 = (props) => {

    const history = useHistory();

    let buttonStyle= {
        backgroundColor: props.params.button_color,
        color: "white"
    }
    
    return <>
    <h1 className="has-text-centered subtitle" >Sei in cassa integrazione?</h1>
        {professioni.map((p,index) => {
        
            return <button class="button is-fullwidth mb-2" key={index} style={buttonStyle} onClick={() => {selectOption(p.value,props.returnData)}}>{p.description}</button>

        })}
        <button class="button is-ghost" onClick={history.goBack}>Indietro</button>
    </>
};


export default Finance_step_12;



const professioni = [
    { id: 8, description: 'Sì', value: true },
    { id: 5, description: 'No', value: false }
]


