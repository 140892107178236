import ConfigKeys from "../config/keys";
import { Component } from "react";

let keys = ConfigKeys.keys();
let apiUrl = keys.apiUrl

export class ApiService extends Component {
    static async getCityByZipCode(query) {
        let data = []
        if (query) {
            const response = await fetch(apiUrl + 'location/' + query);
            data = await response.json();

            if (data.message) {
                data = [];
            }
        }
        return data || [];
    }

    static async getOwner(lead) {


        let dataJson = {
            job: lead.job + (lead.job_info || ""),
            zip: lead.zip,
            type: lead.type,
            affiliate: lead.affiliate,
            oldBuyers: [],
            birthday: lead.birthday,
            amount: lead.amount,
            layoff: lead.layoff || false,
            closedCQ: false
        }
        console.log(dataJson);
        const response = await fetch(apiUrl + 'finance/setup/',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(dataJson)
            });
        let data = await response.json();

        if (data.message) {
            data = [];
        }
        return data;
    }

    static async postLead(lead) {


        const response = await fetch(apiUrl + 'finance/SMSLead/',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(lead)
            });
        let data = await response.json();

       
        return data;
    }



    static async editLead(id, phone) {


        const response = await fetch(apiUrl + 'finance/leads/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "PUT",
                body: JSON.stringify({ phone })
            });
        let data = await response.json();

    
        return data;
    }



    static async verifyLead(id, code) {


        const response = await fetch(apiUrl + 'finance/verify/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ code })
            });
        let data = await response.json();

        return data;
    }


    static async sendSMS(id) {


        const response = await fetch(apiUrl + 'finance/SMS/' + id,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "GET"
            });
        let data = await response.json();

        if (data.message) {
            data = [];
        }
        return data;
    }
};

export default ApiService;