import { useHistory } from "react-router-dom";

function selectOption(value, returnFunction) {
    let needMoreInfo = {
        "pensionato": 6,
        "privato": 3,
    }

    returnFunction({
        data: { "job": value },
        meta: { nextPage: needMoreInfo[value] || 7 }
    });
}



export const Finance_step_1 = (props) => {

    const history = useHistory();

    let buttonStyle = {
        backgroundColor: props.params.button_color,
        color: "white"
    }

    return <>
        <h1 className="has-text-centered subtitle" >Scegli la tua professione</h1>
        {professioni.map((p, index) => {

            return <button class="button is-fullwidth mb-2" key={index} style={buttonStyle} onClick={() => { selectOption(p.value, props.returnData) }}>{p.description}</button>

        })}

        <button class="button is-ghost" onClick={history.goBack}>Indietro</button>
    </>
};


export default Finance_step_1;



const professioni = [
    { id: 7, description: 'Pensionato', value: 'pensionato' },
    { id: 2, description: 'Dipendente parastatale', value: 'parastatale' },
    { id: 1, description: 'Dipendente pubblico', value: 'pubblico' },
    { id: 0, description: 'Dipendente statale', value: 'statale' },
    { id: 8, description: 'Dipendente azienda privata', value: 'privato' },
    { id: 3, description: 'Lavoratore autonomo', value: 'autonomo' },
    { id: 3, description: 'Militare', value: 'militare' },
    { id: 4, description: 'In cerca di occupazione', value: 'disoccupato' },
    { id: 7, description: 'Altro', value: 'altro' },
]


