
import {Component} from "react";

let development = {
    apiUrl: "http://localhost:8080/api/"
}

let production = {

    apiUrl: "https://form.e-leads.it/api/"
}



class ConfigKeys extends Component {
    static keys() {
        var config;

        if (process.env.NODE_ENV == 'production') {
            config = production;
        }
        else {
            config = development;
        }

        return config
    }
};

export default ConfigKeys;