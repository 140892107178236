import { Formik, Field, Form } from 'formik';
import { useState } from 'react';



function validatePrivacy(value) {
    let error;
    if (!value || value === "false") {
        error = 'Accetta le privacy obbligatorie';
    }
    return error;
}

function validatePhone(value) {
    let error;

    let phone = value.replace(/ /g, "");
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{2,3}[-\s\.]?[0-9]{3,6}$/im;

    if (!re.test(String(phone).toLowerCase())) {
        error = 'Inserisci un numero di telefono valido';
    }
    return error;
}


function validateEmail(value) {
    let error;

    let email = value.replace(/ /g, "");
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(email).toLowerCase())) {
        error = 'Inserisci una e-mail valida';
    }
    return error;
}



const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const Finance_step_9 = (props) => {
    const [items, setItems] = useState([])
    const [selected, setSelected] = useState([])
    let owner = props.owner;
    let buttonStyle = {
        backgroundColor: props.params.button_color,
        color: "white"
    }



    return (
        <div>
            <Formik
                initialValues={{
                    phone: '',
                    email: '',
                    privacy_data: false,
                    privacy_sell: false,
                    privacy_prom: false
                }}
                onSubmit={async (values) => {
                    let privacy_1, privacy_2, privacy_3

                    if(values.privacy_data == "false"){
                        privacy_1 = false
                    } else if(values.privacy_data == "true"){
                        privacy_1 = true
                    } else {
                        privacy_1 = values.privacy_data
                    }

                    if(values.privacy_sell == "false"){
                        privacy_2 = false
                    } else if(values.privacy_sell == "true"){
                        privacy_2 = true
                    } else {
                        privacy_2 = values.privacy_sell
                    }


                    if(values.privacy_prom == "false"){
                        privacy_3 = false
                    } else if(values.privacy_prom == "true"){
                        privacy_3 = true
                    } else {
                        privacy_3 = values.privacy_prom
                    }

                    props.returnData({
                        data: {
                            "phone": values.phone,
                            "email": values.email,
                            "privacy_data": privacy_1,
                            "privacy_sell": privacy_2,
                            "privacy_prom": privacy_3

                        },
                        meta: { nextPage: 10, state: "post" }
                    })
                }}
            >
                {({ values, errors, touched, isValidating, isSubmitting, setFieldValue, validateField }) => (
                    <Form>

                        <div className="field">
                            <label className="label">E-mail</label>
                            <div className="control has-icons-right">
                                <Field placeholder="E-mail" name="email" type="email" validate={validateEmail} className={(errors.email && touched.email) ? "input is-danger" : "input"} />
                                {errors.email && touched.email && <span className="icon is-small is-right">
                                    <i className="fas fa-exclamation-triangle"></i>
                                </span>}

                            </div>
                            {errors.email && touched.email && <p className="help is-danger">{errors.email}</p>}
                        </div>
                        <div className="field">
                            <label className="label">Numero di telefono</label>
                            <div className="control has-icons-right">
                                <Field placeholder="Numero di telefono" name="phone" validate={validatePhone} className={(errors.phone && touched.phone) ? "input is-danger" : "input"} />
                                {errors.phone && touched.phone && <span className="icon is-small is-right">
                                    <i className="fas fa-exclamation-triangle"></i>
                                </span>}

                            </div>
                            {errors.phone && touched.phone && <p className="help is-danger">{errors.phone}</p>}
                        </div>

                        {
                            owner.privacies.map(p => {

                                let checkbox = <div className="field" style={(errors[p.paramName] && touched[p.paramName]) ? { color: "red", border: "2px solid red" } : {}}>
                                    <label className="checkbox">
                                        <Field type="checkbox" name={p.paramName} validate={(p.required) ? validatePrivacy : ""} />
                                        <span className="ml-3" dangerouslySetInnerHTML={{ __html: p.text }}></span>
                                    </label>
                                </div>

                                if (!p.checkbox) {

                                    checkbox = <div className="field" style={(errors[p.paramName] && touched[p.paramName]) ? { color: "red", border: "2px solid red" } : {}}>
                                        <div>
                                            <label className="radio">
                                                <Field type="radio" name={p.paramName} value="true" validate={(p.required) ? validatePrivacy : ""} />
                                                <span className="ml-3" >Acconsento</span>
                                            </label>
                                            <label className="radio">
                                                <Field type="radio" name={p.paramName} value="false" validate={(p.required) ? validatePrivacy : ""} />
                                                <span className="ml-3" >Non Acconsento</span>
                                            </label>
                                        </div>
                                        <span className="mt-3" dangerouslySetInnerHTML={{ __html: p.text }}></span>
                                    </div>
                                }

                                return checkbox
                            })
                        }


                        <div className=" has-text-right ">
                            <button type="submit" disabled={isSubmitting} className="button mb-2" style={buttonStyle}>Avanti</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};



export default Finance_step_9;


