import { useHistory } from "react-router-dom";

function selectOption(value,returnFunction) {
   
    returnFunction({
        data: { "job_info": value}, 
        meta: {nextPage: 12}
    });
}
    


export const Finance_step_3 = (props) => {

    const history = useHistory();

    let buttonStyle= {
        backgroundColor: props.params.button_color,
        color: "white"
    }
    
    return <>
    <h1 className="has-text-centered subtitle" >Con quanti dipendenti?</h1>
        {professioni.map((p,index) => {
        
            return <button class="button is-fullwidth mb-2" key={index} style={buttonStyle} onClick={() => {selectOption(p.value,props.returnData)}}>{p.description}</button>

        })}
        <button class="button is-ghost" onClick={history.goBack}>Indietro</button>
    </>
};


export default Finance_step_3;



const professioni = [
    { id: 8, description: 'Meno di 15', value: 'meno15' },
    { id: 5, description: 'Più di 15', value: 'piu15' }
]


