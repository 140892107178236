import { Formik, Field, Form } from 'formik';
import { useState } from 'react';



function validatePrivacy(value) {
    let error;
    if (!value) {
        error = 'Accetta le privacy obbligatorie';
    }
    return error;
}

function validatePhone(value) {
    let error;

    let phone = value.replace(/ /g, "");
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{2,3}[-\s\.]?[0-9]{3,6}$/im;

    if (!re.test(String(phone).toLowerCase())) {
        error = 'Inserisci un numero di telefono valido';
    }
    return error;
}


function validateEmail(value) {
    let error;

    let email = value.replace(/ /g, "");
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(email).toLowerCase())) {
        error = 'Inserisci una e-mail valida';
    }
    return error;
}



const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const Finance_step_11 = (props) => {
    const [items, setItems] = useState([])
    const [selected, setSelected] = useState([])
    let owner = props.owner;
    let buttonStyle = {
        backgroundColor: props.params.button_color,
        color: "white"
    }



    return (
        <div className="has-text-centered">
             <img src={owner.logo} class="img-fluid mb-2" style={{maxWidth: "150px"}}/>
              <h3>Grazie per la tua richiesta!
                {(owner.id!="eleads")?<><br/> Sarai contatto a breve da <b>{owner.name}</b></>:<></>}</h3>
        </div>
    )
};



export default Finance_step_11;


