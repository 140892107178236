import { Formik, Field, Form } from 'formik';
import { useState } from 'react';
import { ApiService } from "../../services/api"



async function validateCode(value) {
    let error;
    if ([...value].length != 6) {
        error = 'Inserisci il codice di 6 cifre';
    }
    return error;
}

async function validatePhone(value) {
    let error;

    let phone = value.replace(/ /g, "");
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{2,3}[-\s\.]?[0-9]{3,6}$/im;

    if (!re.test(String(phone).toLowerCase())) {
        error = 'Inserisci un numero di telefono valido';
    }
    return error;
}





const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const Finance_step_10 = (props) => {

    let buttonStyle = {
        backgroundColor: props.params.button_color,
        color: "white"
    }

    return (
        <div>
            <Formik
                initialValues={{
                    code: ''
                }}
                onSubmit={async (values, { setErrors, errors }) => {
                    try {
                        let res = await ApiService.verifyLead(props.lead.id, values.code)
                        console.log(res)

                        if (res.success) {
                            props.returnData({
                                data: {},
                                meta: { nextPage: 11, state: "end" }
                            })
                        } else {
                            let error = "Questo codice non è valido"
                            setErrors({code: error})
                        }
                    } catch (e) {
                        let error = "Questo codice non è valido"
                        setErrors({code: error})
                    }


                }}
            >
                {({ values, errors, touched, isValidating, isSubmitting, setFieldValue, validateField }) => (
                    <Form>

                        <div className="field has-text-centered mb-5">
                            <i className="fas fa-mobile-alt" style={{ fontSize: "50px" }}></i>
                            <label className="label">
                                Ti abbiamo inviato un SMS con il codice di conferma al numero</label>
                            <div className="control has-icons-right">
                                <Field placeholder="Inserisci qui il codice a 6 cifre" name="code" validate={validateCode} className={(errors.code) ? "input is-danger" : "input"} />
                                {errors.code && touched.code && <span className="icon is-small is-right">
                                    <i className="fas fa-exclamation-triangle"></i>
                                </span>}

                            </div>
                            {errors.code && touched.code && <p className="help is-danger">{errors.code}</p>}
                            <button type="submit" disabled={isSubmitting} className="button mt-2 is-medium is-fullwidth" style={buttonStyle}>Verifica</button>
                        </div>
                    </Form>
                )}
            </Formik>


            <Formik
                initialValues={{
                    phone: props.lead.phone
                }}
                onSubmit={async (values, err) => {
                    try {
                        await ApiService.editLead(props.lead.id, values.phone)
                        await ApiService.sendSMS(props.lead.id);
                    } catch (e) {
                        throw (e);
                    }
                }}
            >
                {({ values, errors, touched, isValidating, isSubmitting, setFieldValue, validateField }) => (
                    <Form>

                        <div className="field has-text-centered mt-5">
                            <label className="label">
                            Non hai ricevuto l'SMS?<br></br> Rinviare il codice al seguente numero :</label>
                            <div className="columns is-mobile">
                                <div className="column is-three-fifths">
                                    <div className="control has-icons-right">
                                        <Field placeholder="Numero di telefono" name="phone" validate={validatePhone} className={(errors.phone && touched.phone) ? "input is-danger" : "input"} />
                                        {errors.code && touched.code && <span className="icon is-small is-right">
                                            <i className="fas fa-exclamation-triangle"></i>
                                        </span>}

                                    </div>
                                    {errors.phone && touched.phone && <p className="help is-danger">{errors.phone}</p>}
                                </div>
                                <div className="column">
                                    <button type="submit" disabled={isSubmitting} className="button is-fullwidth is-link is-outlined" >Reinvia SMS</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};



export default Finance_step_10;


