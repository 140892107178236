import { Formik, Field, Form } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function validateBirthdayDay(value) {
    let error;

    if (!value) {
        error = "Inserisci il giorno di nascita";
    }

    return error;
}

function validateBirthdayMonth(value) {
    let error;

    if (!value) {
        error = "Inserisci il mese di nascita";
    }

    return error;
}

function validateGender(value) {
    let error;

    if (!value) {
        error = "Inserisci il tuo genere";
    }

    return error;
}

function validateBirthdayYear(value) {
    let error;

    if (!value) {
        error = "Inserisci il anno di nascita";
    }

    return error;
}

function validateName(value) {
    let error;
    if (!value) {
        error = "Inserisci il tuo nome";
    }
    return error;
}
function validateSurname(value) {
    let error;
    if (!value) {
        error = "Inserisci il tuo cognome";
    }
    return error;
}

function validateAmount(value) {
    let error;

    if (!value) {
        error = "Inserisci un importo compreso tra 5.000€ a 75.000€";
    }
    if (value > 75000 || value < 5000) {
        error = "Inserisci un importo compreso tra 5.000€ a 75.000€";
    }
    return error;
}

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const Finance_step_6 = (props) => {
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);
    let days = [];
    let months = [];
    let years = [];
    const maxYear = new Date().getFullYear() - 18;
    const minYear = new Date().getFullYear() - 75;

    const history = useHistory();

    let buttonStyle = {
        backgroundColor: props.params.button_color,
        color: "white",
    };

    for (let i = 1; i <= 31; i++) {
        days.push(i);
    }

    for (let i = 1; i <= 12; i++) {
        months.push(i);
    }

    for (let i = minYear; i < maxYear; i++) {
        years.push(i);
    }

    return (
        <div>
            <Formik
                initialValues={{
                    surname: "",
                    amount: 0,
                    name: "",
                    gender: "",
                    birthday_day: "",
                    birthday_month: "",
                    birthday_year: "",
                }}
                onSubmit={async (values) => {
                    props.returnData({
                        data: {
                            surname: values.surname,
                            amount: 0,
                            name: values.name,
                            gender: values.gender,
                            birthday: {
                                day: values.birthday_day,
                                month: values.birthday_month,
                                year: values.birthday_year,
                            },
                        },
                        meta: { nextPage: 2 },
                    });
                }}
            >
                {({ values, errors, touched, isValidating, isSubmitting, setFieldValue, validateField }) => (
                    <Form>
                        {/* 
                        <div className="field">
                            <label className="label">Importo finanziamento</label>
                            <div className="control has-icons-right">
                                <Field placeholder="Importo" max="75000" name="amount" type="number" validate={validateAmount} className={(errors.amount && touched.amount) ? "input is-danger" : "input"} />
                                {errors.amount && touched.amount && <span className="icon is-small is-right">
                                    <i className="fas fa-exclamation-triangle"></i>
                                </span>}

                            </div>
                            {errors.amount && touched.amount && <p className="help is-danger">{errors.amount}</p>}
                        </div> */}
                        <div className="field">
                            <label className="label">Nome</label>
                            <div className="control has-icons-right">
                                <Field
                                    placeholder="Nome"
                                    name="name"
                                    validate={validateName}
                                    className={errors.name && touched.name ? "input is-danger" : "input"}
                                />
                                {errors.name && touched.name && (
                                    <span className="icon is-small is-right">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </span>
                                )}
                            </div>
                            {errors.name && touched.name && <p className="help is-danger">{errors.name}</p>}
                        </div>

                        <div className="field">
                            <label className="label">Cognome</label>
                            <div className="control has-icons-right">
                                <Field
                                    placeholder="Cognome"
                                    name="surname"
                                    validate={validateSurname}
                                    className={errors.surname && touched.surname ? "input is-danger" : "input"}
                                />
                                {errors.surname && touched.surname && (
                                    <span className="icon is-small is-right">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </span>
                                )}
                            </div>
                            {errors.surname && touched.surname && <p className="help is-danger">{errors.surname}</p>}
                        </div>

                        <div className="field">
                            <label className="label">Genere</label>
                            <div className="control has-icons-right">
                                <div
                                    className={
                                        errors.birthday_day && touched.birthday_day
                                            ? "select is-fullwidth is-danger"
                                            : "select is-fullwidth"
                                    }
                                >
                                    <Field placeholder="Genere" name="gender" as="select" validate={validateGender}>
                                        <option>Genere</option>
                                        <option value="M">Maschio</option>
                                        <option value="F">Femmina</option>
                                    </Field>
                                    {errors.gender && touched.gender && (
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-exclamation-triangle"></i>
                                        </span>
                                    )}
                                </div>
                            </div>
                            {errors.gender && touched.gender && <p className="help is-danger">{errors.gender}</p>}
                        </div>

                        <div className="field">
                            <label className="label">Data di nascita</label>

                            <div class="columns is-mobile">
                                <div class="column">
                                    <div
                                        className={
                                            errors.birthday_day && touched.birthday_day
                                                ? "select is-fullwidth is-danger"
                                                : "select is-fullwidth"
                                        }
                                    >
                                        <Field
                                            placeholder="Giorno"
                                            name="birthday_day"
                                            as="select"
                                            validate={validateBirthdayDay}
                                        >
                                            <option>Giorno</option>
                                            {days.map((v) => (
                                                <option key={v} value={v}>
                                                    {v}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                                <div class="column">
                                    <div
                                        className={
                                            errors.birthday_month && touched.birthday_month
                                                ? "select is-danger is-fullwidth"
                                                : "select is-fullwidth"
                                        }
                                    >
                                        <Field
                                            placeholder="Mese"
                                            name="birthday_month"
                                            as="select"
                                            validate={validateBirthdayMonth}
                                        >
                                            <option>Mese</option>
                                            {months.map((v) => (
                                                <option key={v} value={v}>
                                                    {v}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                                <div class="column">
                                    <div
                                        className={
                                            errors.birthday_year && touched.birthday_year
                                                ? "select is-danger is-fullwidth"
                                                : "select is-fullwidth"
                                        }
                                    >
                                        <Field
                                            placeholder="Anno"
                                            name="birthday_year"
                                            as="select"
                                            validate={validateBirthdayYear}
                                        >
                                            <option>Anno</option>
                                            {years.map((v) => (
                                                <option key={v} value={v}>
                                                    {v}
                                                </option>
                                            ))}
                                        </Field>
                                    </div>
                                </div>
                            </div>

                            {errors.birthday_day && touched.birthday_day && (
                                <p className="help is-danger">{errors.birthday_day}</p>
                            )}
                            {errors.birthday_month && touched.birthday_month && (
                                <p className="help is-danger">{errors.birthday_month}</p>
                            )}
                            {errors.birthday_year && touched.birthday_year && (
                                <p className="help is-danger">{errors.birthday_year}</p>
                            )}
                        </div>

                        <div className="columns is-mobile">
                            <div className="column has-text-right">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="button mb-2"
                                    style={buttonStyle}
                                >
                                    Avanti
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Finance_step_6;
