import logo from './logo.svg';
import { Finance_step_1 } from "./components/finance-step-1"
import { Finance_step_2 } from "./components/finance-step-2"
import { Finance_step_3 } from "./components/finance-step-3"
import { Finance_step_4 } from "./components/finance-step-4"
import { Finance_step_5 } from "./components/finance-step-5"
import { Finance_step_6 } from "./components/finance-step-6"
import { Finance_step_7 } from "./components/finance-step-7"
import { Finance_step_8 } from "./components/finance-step-8"
import { Finance_step_9 } from "./components/finance-step-9"
import { Finance_step_10 } from "./components/finance-step-10"
import { Finance_step_11 } from "./components/finance-step-11"
import { Finance_step_12 } from "./components/finance-step-12"
import ApiService from './services/api';

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import React, { useState } from 'react';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



export const App = () => {
  const query = useQuery();
  const history = useHistory();
  let oldBuyers = []

  if (localStorage.getItem('el_oldBuyers')) {
    oldBuyers = JSON.parse(localStorage.getItem('el_oldBuyers'));
  }

  const [params, setParams] = useState({
    bg_color: query.get("bg_color") || "#ffffff",
    button_color: query.get("btn_color"),
    aff_id: query.get("aff_id"),
    aff_ref: query.get("aff_ref"),
    type: query.get("type"),
    orientation: query.get("orientation"),
    host: query.get("host"),
    url: query.get("url"),
    frameId: query.get("frameId")
  });

  const [lead, setLead] = useState({
    affiliate: params.aff_id,
    url: params.url,
    aff_ref: params.aff_ref,
    type: params.type,
    host: params.host,
    gender: "",
  });

  const [owner, setOwner] = useState({});
  const [loading, setLoading] = useState(false);

  let [page, setPage] = useState(1)


  setInterval(function () {
    window.parent.postMessage(
      // get height of the content
      {
        height: document.body.scrollHeight,
        id: params.frameId
      }
      // set target domain
      , "*"
    )
  }, 150);

  const handleReturnData = async (data) => {
    Object.keys(data.data).map(k => {
      lead[k] = data.data[k]
    })


    setLead(lead);


    switch (data.meta.state) {
      case "setup":
        setLoading(true);
        let ownerRet = await ApiService.getOwner({ ...lead, oldBuyers });

        setOwner(ownerRet);
        lead.owner = ownerRet.id || "e-leads"
        lead.needToQualify = ownerRet.needToQualify
        setLoading(false);

        if (!ownerRet.fiscalCode) {
          data.meta.nextPage += 1;
        }

        break;

      case "post":
        setLoading(true);
        let leadPosted = await ApiService.postLead(lead);

        lead.id = leadPosted.id;
        lead.double = leadPosted.double;
        setLead(lead);

        if (!owner.needToVerify) {
          data.meta.nextPage += 1;
          sendMessageDone(lead, owner, lead.double,oldBuyers)
        } else {
          await ApiService.sendSMS(lead.id);
        }

        setLoading(false);

        break;


      case "end":
        sendMessageDone(lead, owner, lead.double,oldBuyers)

        break;

      default:
        break;
    }


    history.push("/" + data.meta.nextPage);
    setPage(data.meta.nextPage);
  }

  return (
    <div className="form-container" style={{ backgroundColor: params.bg_color }}>
      <div className="container" >

        <div className="loader-wrapper" style={(!loading) ? { display: "none" } : {}}>
          <div className="loader is-loading"></div>

        </div>
        <Switch>
          <Route exact path="/">
            <Finance_step_6 returnData={handleReturnData} params={params}></Finance_step_6>
          </Route>
          <Route  path="/2">
            <Finance_step_1 returnData={handleReturnData} params={params}></Finance_step_1>
          </Route>
          <Route path="/3">
            <Finance_step_2 returnData={handleReturnData} params={params}></Finance_step_2>
          </Route>
          <Route path="/4">
            <Finance_step_3 returnData={handleReturnData} params={params}></Finance_step_3>
          </Route>
          <Route path="/5">
            <Finance_step_4 returnData={handleReturnData} params={params}></Finance_step_4>
          </Route>
          <Route path="/6">
            <Finance_step_5 returnData={handleReturnData} params={params}></Finance_step_5>
          </Route>
          <Route path="/7">
            <Finance_step_7 returnData={handleReturnData} params={params}></Finance_step_7>
          </Route>
          <Route path="/8">
            <Finance_step_8 returnData={handleReturnData} params={params}></Finance_step_8>
          </Route>
          <Route path="/9">
            <Finance_step_9 returnData={handleReturnData} params={params} owner={owner}></Finance_step_9>
          </Route>
          <Route path="/10">
            <Finance_step_10 returnData={handleReturnData} params={params} lead={lead}></Finance_step_10>
          </Route>
          <Route path="/11">
            <Finance_step_11 returnData={handleReturnData} params={params} owner={owner}></Finance_step_11>
          </Route>

          <Route path="/12">
            <Finance_step_12 returnData={handleReturnData} params={params}></Finance_step_12>
          </Route>

        </Switch>

        <div className="mt-2 columns">
          <div className="column has-text-centered has-text-success is-size-5">
            <i className="fas fa-lock"></i> I tuoi dati sono al sicuro
          </div>
        </div>
        {
          (owner.name && owner.id != "eleads") ? <div className="row mt-5">
            <div className="is-text-center">
              E-leads S.r.l. gestisce unicamente gli aspetti tecnici del modulo di raccolta dati. {owner.name} riceverà
              la
              vostra richiesta quale titolare autonomo in conformità all’ Informativa privacy. E-leads non è un agente o
              un mediatore creditizio, non fornisce consulenza, né è parte del rapporto commerciale tra l’utente e
              l’operatore che riceve la richiesta, ma si limita a mettere a disposizione la tecnologia necessaria affinché
              l’utente possa contattare direttamente l’operatore
            </div>
          </div> : <></>
        }
      </div>
    </div>
  )

};


function sendMessageDone(lead, owner, double, oldBuyers) {
  let sold = true;
  let normalQuality = [
    'cooperativa',
    'srl'
  ]

  let topQuality = [
    'parastatale',
    'pubblico',
    'militare',
    'pensionato',
    'statale',
    'spa'
  ]


  let goal
  switch (lead.job + lead.job_info) {
    case "pubblico":
    case "statale":
    case "pensionatoanzianita":
    case "parastatale":
    case "militare":
      goal = "pubblico-basic";
      break;

    case "cooperativapiu50":
    case "srlpiu15":
    case "spapiu15":
      goal = "privato-basic";
      break;

    default:
      goal = "privatomeno15-basic";
      break;
  }



  if (owner.id === "eleads") {
    sold = false;
  } else {
    localStorage.setItem("el_oldBuyers", JSON.stringify(oldBuyers));
  }

  window.parent.postMessage({ sender: 'e-leads', message: "done", id: lead.id, double: double, sold, logo: owner.logo, qualify: owner.needToQualify, goal }, '*');
}


export default App;


